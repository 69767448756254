<template>
  <div class="modal-card" :style="ModalWidth">
    <header class="modal-card-head" style="padding: 10px;">
      <p class="modal-card-title">Modal</p>
    </header>
    <section class="modal-card-body">
      <div class="tab-item" v-if="loading">
        <b-progress type="is-info"></b-progress>
      </div>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!loading"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Close
        </b-button>
      </div>
      <div>
        <b-button @click="submitForm(true)">
          Save
        </b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
export default {
  name: "ModalChat",
  mixins: [Utils, Schemas],
  props: {
    data: Object,
  },
  data() {
    return {
      DataIn: this.data,
      Data: {},
      loading: true,
    };
  },
  created() {
    this.DataLoad();
  },
  methods: {
    async DataLoad() {
      if (this.DataIn._id === "new") {
        this.Data = this.SchemaNew(this.dataSchema);
      } else {
        const indata = await this.axiosget(`/url/${this.DataIn._id}`);
        this.Data = indata.rows;
      }
      this.setWidth();
      this.loading = false;
    },
    setWidth() {
      const inw = document.body.clientWidth;
      const inh = document.body.clientHeight;
      let newsize = `width: auto`;
      if (inw > 750) {
       const usize = inh - 100;
        newsize = `width: 700px; margin-left: 25px; margin-right: 25px; height: ${usize}px;`;
        // newsize = `width: ${usize}px; height: 80%;`;
      }
      this.ModalWidth = newsize;
    },
    async submitForm(close) {
      const updata = await this.DataSend("put", "/url", this.Data);
      let type = "update";
      if (this.Data._id === "new") {
        this.Data._id = updata.rows._id;
        this.Data.assignedto = updata.rows.assignedto;
        type = "new";
      }
      this.$emit("dataUpdated", { type, data: updata.rows });
      if (close) {
        this.$parent.close();
      }
    },
  }
};
</script>
