<template>
  <div class="contentbody">
    <b-loading is-full-page :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="contentleft" v-if="w.left">
      <div class="contentleft-inner">
        <div class="contentleft-header">
          <div class="mr5" @click="AddNew()">
            <b-button icon-left="plus">New </b-button>
          </div>
          <div class="contentleft-header-searchbox">
            <b-input placeholder="Search" type="search" icon="search" v-model="searchField" @input="doSearch()" ></b-input>
          </div>
        </div>

        <div class="contentleft-body scrolly scrollx">
          <div class="tsk p7 m7 isFlex" v-for="(n, index) in notifyList" :key="index" @click="openChat(n, index)">
            <div v-if="n.type === 'Invite'"><font-awesome-icon :icon="['fal', 'bullseye-pointer']" size="lg"></font-awesome-icon></div>
            <div v-if="n.type === 'Chat'" v-bind:class="{ unread: !n.hasRead }"><font-awesome-icon :icon="['fal', 'comments-alt']" size="lg"></font-awesome-icon></div>
            <div v-if="n.type === 'Intro'" v-bind:class="{ unread: !n.hasRead }"><font-awesome-icon :icon="['fal', 'people-arrows']" size="lg"></font-awesome-icon></div>
            <div v-if="n.type === 'Request'"><font-awesome-icon :icon="['fal', 'hand-holding-seedling']" size="lg"></font-awesome-icon></div>
            <div class="ml11 isFlexGrow">
              <div class="tskhead">{{ n.subject }}</div>
              <div class="txtsm">Last edit: {{ n.subname }} Date: {{ n.updatedAt | displaydateFull }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contentright" v-if="w.right">
      <div class="contentright-inner">
        <div class="contentright-nav">
          <div class="iconbutton mv5" @click="closeRight()">
            <font-awesome-icon :icon="['fal', 'arrow-alt-left']" size="lg" ></font-awesome-icon>
          </div>
          <div class="iconbutton mv5" @click="openChat(ActiveChat, 0)">
            <font-awesome-icon :icon="['fal', 'sync-alt']" size="lg" ></font-awesome-icon>
          </div>
        </div>
        <div class="contentright-body">
          <div class="contentright-body-body scrolly m9">
            <div v-if="isNew">
              <b-field label="Add recipient">
                <b-select placeholder="Select a recipient to add" @input="AddTo" expanded>
                  <option value="">Select a recipient</option>
                  <option
                    v-for="option in peopleList"
                    :value="option"
                    :key="option._id">
                    {{ option.username }} 
                  </option>
                </b-select>
              </b-field>
              <div class="isFlex">
                <div class="txtbold mr11">To:</div>
                <div class="isFlexGrow isFlex">
                  <div class="tsk p3 isFlex bluebg1 mr11" v-for="(t, ti) in newNotify.to" :key="ti">
                    <div>{{t.username}}</div>
                    <div class="iconbutton" @click="removeTo(ti)">
                      <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                </div>
              </div>
              <b-field label="Assign to goal" class="mt7">
                <b-select placeholder="Select Goal" expanded v-model="newNotify.goalid">
                  <option v-for="gc in GoalList" :value="gc._id" :key="gc._id">{{ gc.goaltitle }}</option>
                </b-select>
              </b-field>
              <b-field label="Subject">
                <b-input v-model="newNotify.subject" placeholder="Please enter a subject"></b-input>
              </b-field>
              <b-field label="Message">
                <b-input v-model="newNotify.message" maxlength="500" type="textarea"></b-input>
              </b-field>
              <div class="buttons">
                <b-button expanded @click="CreateChat">Create Chat</b-button>
              </div>
            </div>
            <div v-else>
              <div class="txtsm">Created by: {{ ActiveChat.subname }} Date: {{ ActiveChat.updatedAt | displaydateFull }} </div>

              <div class="txth2">{{ ActiveChat.subject }}</div>

              <div v-if="ActiveChat.type === 'Invite'">
                <div>Sent to {{ ActiveChat.useremail }}</div>
              </div>
              <div v-if="ActiveChat.type === 'Chat' || ActiveChat.type === 'Intro' || ActiveChat.type === 'Request'">
                <div class="tsk vlgreybg lgreyborder">
                  <div class="txtmd mb3">{{ ActiveChat.subname }} @ {{ ActiveChat.updatedAt | displaydateFull }} </div>
                  <div v-html="ActiveChat.message"></div>
                  <template v-if="ActiveChat.type === 'Intro'">
                    <div class="buttons mt11" v-if="ActiveChat.sub !== this.sub">
                      <div class="mb11">
                        <div>Sent by: <a class="txtbold" @click="viewProfile(ActiveChat)">{{ActiveChat.subname}}</a></div>
                      </div>
                    </div>
                    <div class="mt5" v-if="ActiveChat.sub === this.sub">
                      <div class="mb11">Sent to: <a class="txtbold" @click="viewProfile(ActiveChat.to[0])">{{ActiveChat.to[0].username}}</a></div>
                    </div>

                  </template>
                </div>

                

                <div class="mt11" v-if="ActiveChat.type === 'Request' && ActiveChat.userid === sub">
                  <div class="tsk bluebg10 white">
                    Please create a new install of the blueprint and then invite the following person via their e-mail: {{ ActiveChat.useremail }}<br/><br/>
                    It is also polite to send a quick hello / introduction message. 
                  </div>
                </div>

                <div v-for="(r, ri) in ActiveChat.replies" :key="ri">
                  <div class="tsk vlgreybg lgreyborder mt11">
                    <div class="txtsm mb3">{{ r.subname }} @ {{ r.date | displaydateFull }} </div>
                    <div>{{ r.message }}</div>
                  </div>
                </div>
              </div>
            </div>
          


          </div>
          <div class="p11 vlgreybg" v-if="ActiveChat.type === 'Chat' || ActiveChat.type === 'Intro' || ActiveChat.type === 'Request'">
            <template v-if="ActiveChat.sub !== this.sub">
              <div class="mb5" v-if="ActiveChat.type === 'Intro' && !incircles"><b-button type="is-warning" expanded @click="addToCircles">Add {{ActiveChat.subname}} to your circle</b-button></div>
              <div class="mb5" v-if="ActiveChat.type === 'Intro' && incircles"><b-button type="is-light" expanded @click="removeFromCircles">Remove {{ActiveChat.subname}} from your circles</b-button></div>
            </template>
            <template v-if="ActiveChat.sub === this.sub">
              <div class="mb5" v-if="ActiveChat.type === 'Intro' && incircles"><b-button type="is-light" expanded @click="removeFromCircles">Remove {{ActiveChat.to[0].username}} from your circles</b-button></div>
            </template>
            <div><b-input v-model="newReply" maxlength="500" rows="2" type="textarea" placeholder="Post a reply"></b-input></div>
            <div class="buttons">
              <b-button type="is-info" expanded @click="postReply">Post Reply</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import array from "lodash/array";
import debounce from "lodash/debounce";
// import findLastIndex from "lodash/findLastIndex";
import { windowSplit2 } from "../mixins/windowSplit.js";
import ModalChat from "@/modals/ModalChat.vue";

export default {
  mixins: [Utils, windowSplit2],
  name: 'blank',
  data() {
    return {
      notifyList: [],
      peopleList: [],
      searchField: '',
      ActiveChat: {},
      isNew: false,
      newNotify: {
        to: [],
        subject: '',
        message: '',
        type: 'Chat',
        goalid: '',
        goaltitle: '',
        goalcolor: '',
      },
      isLoading: true,
      newReply: '',
      GoalList: [],
      incircles: false,
      ActiveCircle: {},
      sub: localStorage.getItem("sub")
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getpeople();
    this.getNotify();
    this.getGoals();
  },
  methods: {
    async getpeople() {
      const inp = await this.DataGet('/notify/people');
      this.peopleList = inp;
    },

    async getGoals() {
      this.GoalList = [];

      const glin = await this.DataGet("/goals/select");
      const gl = glin;

      // if(this.isNew) {
      //   this.GoalList.push(this.goalSelected);
      // } else {
      //   this.goalSelected = this.inGoal;
      //   this.GoalList.push(this.goalSelected);
      // }

      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        const nr = {
          _id: g._id,
          goaltitle: g.goaltitle,
          goalcolor: g.goalcolor
        }
        this.GoalList.push(nr);
      }
      if(!this.isNew) {
        this.GoalList.push({
        _id: "",
        goaltitle: "Not associated with a goal",
        goalcolor: "#CCC"
      });
      }
      this.loading = false;
    },

    doSearch: debounce(function() {
      this.getNotify();
    }, 2000),

    async getNotify() {
      this.isLoading = true;
      const gnotify = await this.DataSend('post', '/notify/all', {}, 'rows');
      for (const row of gnotify) {
        row.hasRead = false;
        if (row.sub === this.sub) {
          row.hasRead = row.read;
        } else {
          const hasrecord = array.findLastIndex(row.to, { '_id': this.sub });
          row.hasRead = row.to[hasrecord].read;
        }
        row.message = row.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
      this.notifyList = gnotify;
      this.isLoading = false;
    },

    async openChat(row, index) {
      this.isLoading = true;
      this.isNew = false;
      this.incircles = false;
      const gc = await this.DataGet(`/notify/${row._id}`, 'rows', false);
      gc.message = gc.message.replace(/(?:\r\n|\r|\n)/g, '<br>');

      if (gc.type === 'Intro') {
        const getcirc = await this.DataSend('post', '/incircle', { sub: this.sub, toid: gc.to[0]._id }, 'rows', false)
        if (getcirc !== null) {
          this.incircles = true;
          this.ActiveCircle = getcirc;
        }
      }
      this.ActiveChat = gc;
      if (this.ActiveChat.sub === this.sub) {
        this.ActiveChat.read = true;
        this.notifyList[index].hasRead = true;
      } else {
        const hasrecord = array.findLastIndex(this.ActiveChat.to, { '_id': this.sub });
        this.ActiveChat.to[hasrecord].read = true;
        this.notifyList[index].hasRead = true;
      }
      const upd = {
        _id: this.ActiveChat._id,
        read: this.ActiveChat.read,
        to: this.ActiveChat.to,
      }
      this.DataSend('post', '/notify/updatefew', upd, '', false);
      this.openRight();
      this.isLoading = false;
    },

    async AddNew() {
      this.isNew = true;
      this.openRight();
    },

    async AddTo(data) {
      data.read = false;
      this.newNotify.to.push(data);
    },

    async CreateChat() {
      if (this.newNotify.goalid !== '') {
        const gindex = array.findLastIndex(this.GoalList, { '_id': this.newNotify.goalid });
        this.newNotify.goaltitle = this.GoalList[gindex].goaltitle;
        this.newNotify.goalcolor = this.GoalList[gindex].goalcolor;
      }
      
      if(this.newNotify.to.length === 0) {
        this.$buefy.snackbar.open({ message: "Please select at least one recipient", position: "is-top" });
        return;
      }
      if(this.newNotify.subject === '') {
        this.$buefy.snackbar.open({ message: "Please enter a subject", position: "is-top" });
        return;
      }
      if(this.newNotify.message === '') {
        this.$buefy.snackbar.open({ message: "Please enter a message", position: "is-top" });
        return;
      }
      this.isLoading = true;
      const newc = await this.DataSend('post', '/notify/new', this.newNotify, 'rows');
      this.notifyList.unshift(newc);
      this.newNotify.to = [];
      this.newNotify.subject = '';
      this.newNotify.message = '';
      this.isNew = false;
      this.openChat(newc, 0);
    },

    async postReply() {
      if(this.newReply === '') {
        this.$buefy.snackbar.open({ message: "Please enter a reply", position: "is-top" });
        return;
      }
      const data = {
        _id: this.ActiveChat._id,
        repid: 'new',
        message: this.newReply 
      }
      this.isLoading = true;
      const newr = await this.DataSend('post', '/notify/reply', data, 'rows');
      this.ActiveChat.replies.push(newr);
      this.newReply = '';
      this.isLoading = false;
    },

    removeTo(index) {
      this.newNotify.to.splice(index, 1);
    },

    viewProfile(p) {
      let purl = '';
      if (p.handle === '') {
          // u.profileurl = `${window.location.origin}/whois/${u._id}`;
          purl = `/whois/${p.sub}`;
        } else {
          // u.profileurl = `${window.location.origin}/iam/${u.handle}`;
          purl = `/iam/${p.handle}`;
        }
        this.$router.push({ path: purl });
    },

    addToCircles() {
      this.$buefy.dialog.prompt({
        message: `Add a note against this profile`,
        inputAttrs: {
          placeholder: "Add to circle",
          maxlength: 200
        },
        trapFocus: true,
        onConfirm: async value => {
          // this.$buefy.snackbar.open({ message: 'User added to you circle', type: 'is-warning', position: 'is-bottom' });
          this.DataSend('post', '/addtocircles', { _id: this.ActiveChat.sub, note: value });
          this.incircles = true;
        }
      });
    },

    removeFromCircles() {
      this.$buefy.dialog.confirm({ 	
      title: '',	
        message: 'Are you sure you want to remove this person from your circles?',	
        confirmText: 'Remove',	
        type: 'is-danger',	
        hasIcon: true,	
        icon: 'exclamation',	
        onConfirm: () => {
          this.DataDelete(`/circles/${this.ActiveCircle._id}`);
          this.incircles = false;
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    // EventBus.$off('task_open');
    // EventBus.$off('task_added');
  }
}
</script>

<style>
.unread {
  color: red;
}
</style>
